import React from 'react'
import styled from 'styled-components'
import Textarea from '@common/form/Textarea'

const RadioInput = styled.input`
  margin: .4rem;
`


const RadioTextField = (props) => {

  const {
    id, 
    name, 
    label, 
    checked, 
    placeholder, 
    onRadioClick,
    onTextChange
  } = props


  return (
    <>
    <RadioInput 
      type="radio" 
      id={`${id}-radio`} 
      name={name} 
      value={label}
      checked={checked}
      onChange = {e => {
        e.persist();
        onRadioClick()
      }}
    />
    <label htmlFor="option_1">{label}</label> 
    {checked && 
      <Textarea 
        type="textarea" 
        name={name} 
        id={`${id}-textarea`} 
        placeholder={placeholder}
        onChange = {e => {
          e.persist();
          onTextChange(e.target.value)
        }}
      />
    } 
  </>
  )
}

export default RadioTextField