import React, {useState, useContext} from "react"
import { navigate } from "gatsby"
import {FirebaseContext} from '@components/Firebase'
import { Button,  SpinnerOverlay} from '@common'
import FlexWrapper from '@common/layout/FlexWrapper'
import Form from '@form/Form'
import FlashMessage from '@notification/FlashMessage'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '@hooks/usePageContext'
import RadioTextfield from '@form/RadioTextfield'


const Fieldset = styled.fieldset`
  border: none;
  padding:0px;
  margin:0px;
  ul{
    list-style-type: none;
    /* padding-inline-start: 10px;*/
    margin: 0px; 
    padding-left: 20px;
    text-indent: -25px;
    li{
      margin-top:10px;
    }
  }
  label{
    padding-left:0px;
  }
  legend{
    margin: 0px;
    padding: 0px;
    text-align: center;
  }
`

const RadioWrapper = styled.div`
  display:block;
`

const CancelForm = styled(Form)`
  padding: 20px 0px;
  @media (min-width:600px) {
    padding: 150px 0px;
  }
`


const CancelOptions = (props) => {
  const {firebase, user} = useContext(FirebaseContext);
  const [flashMessage, setFlashMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('option-0');
  const [feedback, setFeedback] = useState({text:""});
  const { t } = useTranslation()
  const { lang } = usePageContext();


  function handleSubmit(e){
   e.preventDefault();
  let  planInterval;
  if(props.location.state !== undefined){
    planInterval = props.location.state.subscriptionInterval
  }
    e.preventDefault();
    if(feedback.text === ''){
      setFlashMessage({message: t('cancel-survey.flashMessage-1'),type:"error"})
    } else {
      if(user.isPaypalUser){
        setLoading(true)
        firebase.sendCancelFeedback(feedback, lang)
        firebase.cancelPaypal().then(()=> {
          setLoading(false)
          setFlashMessage({message:t('cancel-offers.flashMessage-3'),type:"success"});
          setTimeout(()=>{  navigate(t('urls.root')); }, 2500);
        }).catch((error) => {
          setFlashMessage({message:t('cancel-survey.flashMessage-2'),type:"error"});      
        })
      } else {
        navigate(t('urls.cancel-offers'),{state: { planInterval }});
      }
    } 
  }
 
  const options = [
    {
      label: t('cancel-survey.cancelReason-1'),
      placeholder:t('cancel-survey.cancelPlaceholder-1')
    },
    {
      label: t('cancel-survey.cancelReason-2'),
      placeholder:t('cancel-survey.cancelPlaceholder-2')
    },
    {
      label: t('cancel-survey.cancelReason-3'),
      placeholder:t('cancel-survey.cancelPlaceholder-3')
    },
    {
      label: t('cancel-survey.cancelReason-4'),
      placeholder:t('cancel-survey.cancelPlaceholder-4')
    },
    {
      label: t('cancel-survey.cancelReason-5'),
      placeholder:t('cancel-survey.cancelPlaceholder-5')
    },
    {
      label: t('cancel-survey.cancelReason-6'),
      placeholder:t('cancel-survey.cancelPlaceholder-6')
    },
    {
      label: t('cancel-survey.cancelReason-7'),
      placeholder:t('cancel-survey.cancelPlaceholder-7')
    }
  ]

  

  return (
    <FlexWrapper around column>
      <SpinnerOverlay show={loading}/>
      <CancelForm onSubmit={handleSubmit}>
          <Fieldset>
          <legend><h2>{t('cancel-survey.sectionTitle')}</h2></legend>
          {flashMessage && <FlashMessage message={flashMessage}/>}
          <RadioWrapper>
            <ul>
              {options.map((option,index) => (
                <li key={index}>
                  <RadioTextfield
                    id={index}
                    name={"options"}
                    label={option.label}
                    checked={selectedOption === `option-${index}`}
                    placeholder={option.placeholder}
                    onRadioClick={() => setSelectedOption(`option-${index}`)}
                    onTextChange={(text) => setFeedback({reason:option.label,text:text})}
                  />
                </li>
              ))}
            </ul>
          </RadioWrapper>
          </Fieldset>
          <Button marginTop  type="submit" block>{t('cancel-survey.submitButton')}</Button>
        </CancelForm>
     
    </FlexWrapper>
  )
}

export default CancelOptions