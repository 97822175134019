import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:${props => props.column? 'column' : 'row'};
  padding:${props => props.around ? '20px 20px 20px 20px' : ''};
  padding:${props => props.sides ? '0px 20px 0px 20px' : ''};
  padding:${props => props.topbot ? '20px 0px 20px 0px' : ''};
  padding:${props => props.top ? '20px 0px 0px 0px' : ''};
  padding:${props => props.bot ? '0px 0px 0px 20px' : ''};
  margin:${props => props.marginTopBot ? '15% 0px;' : ''};
`
export default FlexWrapper 